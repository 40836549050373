import React, {  useContext, useEffect, useState } from 'react'
import cl from './make_bids.module.css'
import Bid from './Bid'
import { fetchDataContext, IsPreparingContext, TotalSumContext } from '../../../context/allContext'



const MakeBids = () => {
  const {isPreparing, setIsPreparing} = useContext(IsPreparingContext)
  const {TotalSum, setTotalSum} = useContext(TotalSumContext)
  const {fetching, setFetch} = useContext(fetchDataContext)
  const [bidding, changeBidding] = useState({
    first: false,
    sec: false
  })
  
  useEffect(() =>{
    if (!isPreparing){
      changeBidding({first:'', sec:''})
    }else{
      let amount;
      if(bidding.first && bidding.sec){
        amount = `-${Number(bidding.first) + Number(bidding.sec)}`
      } else if (bidding.first){
        amount = `-${Number(bidding.first)}`
      } else if(bidding.sec){
        amount = `-${Number(bidding.sec)}`
      }

      if(amount){
        changeSum(amount)
      }
    }
  }, [isPreparing])

  function changeSum(sum){
    fetch('/change_sum_total',{
      method: 'PUT',
      headers: {
          'Accept': 'application/json, text/plain',
          'Content-Type': 'application/json;charset=UTF-8',
          'X-CSRFToken':fetching
      },
      body: JSON.stringify({amount: sum})
    })
    .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok, can not change sum');
        }
        return response.json();
      })
      .then((result) => {
        setTotalSum(result['new_sum']); 

      })
      .catch((error) => {
        console.error('Fetch errorcwith changing sum:', error);})
      
    }
  
  return (
    <div className={cl.bids }>
                <Bid  bid='1' bidding = {bidding} changeBidding={changeBidding}/>
                <Bid bid='2' bidding = {bidding} changeBidding={changeBidding}/>
    </div>
  )
}

export default MakeBids