import React, { useEffect, useState, useContext } from 'react'
import { IsRoundContext } from '../../../../context/allContext'

const Svg = ({ height, width, plane, view, changePlane}) => {

    const {isRound, setIsRound} = useContext(IsRoundContext)

    const [m_x, setMX] = useState(0)
    const [y_x, setYX] = useState(0)
    const [Q, setQ] = useState({ q3:0, q4:0})
    const [l1, setL] = useState(0)
    const [planeS, setPlane] = useState({width:0, height:0})
    const [display, setDisplay] = useState(false)
    const [q1, setQ1] = useState(0)
    const [q3, setQ3] = useState(0)
    let onChange = false
    
    useEffect(()=>{
        setPlane({width:Number( plane.width), height:Number(plane.height)})
        
    },[plane])

    useEffect(() =>{
        setMX(Number(width)* 0.05)
        setYX(Number(height)*0.93)
        if(onChange){
            setQ1(Number(width)* 0.05*5)
        }else{
            setQ1(Number(width)* 0.05*2)
        }
        setQ3(Number(width)* 0.05+2*planeS.width)
        
    },[height, width])

    useEffect(() =>{
        let interval
        if(isRound){
            setQ3(Number(m_x)+2*planeS.width)
            interval = setInterval(UpdatePath, 24)
            setDisplay('block')
              
        }else{
            setDisplay('none')
            clearInterval(interval)
            onChange = false
            setQ({q3:0, q4:0})
            setL(0)
            setQ1(Number(m_x)*2)

        }
    },[isRound])

    

    function UpdatePath(){
        let [plane_left,plane_top] = changePlane()


        let relativeLeft =  Number(plane_left) - Number(view.left) 
        let relativeTop = Number(plane_top) - Number(view.top) 

        if(relativeLeft < Number(m_x)*5  ){
            setQ({ q3:relativeLeft+10, q4:relativeTop+planeS.height/2})
            
        }else{
            if (!onChange){
                onChange = true
                setQ1(Number(m_x)*5)
            }
            setQ({q3:relativeLeft+Number(m_x)/2, q4:relativeTop+planeS.height/1.97})
        }
        setL(relativeLeft+Number(m_x)/2.1)
    }


  return (
    <svg style={{ height: "100%", width: "100%", display: display}} id="svg">
                    <defs>
                        <linearGradient id="grad" x1="0" x2="1" y1="0" y2="1">
                            <stop stopColor="#f20a3d" stopOpacity=".55"></stop>
                            <stop offset=".987" stopColor="#f20a3d" stopOpacity="0"></stop>
                        </linearGradient>

                        <linearGradient id="grad_stroke" x1="0" x2="1" y1="0" y2="1">
                            <stop stopColor="#f20a3d"></stop>
                            <stop offset=".787" stopColor="#b10945"></stop>
                            <stop offset="1" stopColor="#5c24fc" stopOpacity="0"></stop>
                        </linearGradient>
                    </defs>

                    <g>
                        <path id="path_stroke" d={`M ${m_x} ${y_x} Q ${q1} ${y_x} ${Q.q3} ${Q.q4}`} fill="transparent" stroke="url(#grad_stroke)" strokeWidth="4"></path>
                        <path id="path_background" d={`M ${m_x} ${y_x} Q ${q1} ${y_x} ${Q.q3} ${Q.q4} L ${l1} ${y_x} Z`} fill="url(#grad)"></path>
                    </g>

                </svg>

  )
}

export default Svg