import React, { useContext, useEffect, useRef, useState } from 'react'
import classes from '../rightsection.module.css'
import AxisCoordinates from '../coordinates/AxisCoordinates'
import StartRound from '../coordinates/UI/StartRound'
import Stopwatch from './Stopwatch'
import Glow from './Glow'
import AxisXCoordination from '../coordinates/AxisXCoordination'
import { IsPreparingContext, IsRoundContext, isPostContext} from '../../../../context/allContext'
import Svg from './Svg'

const Game = ( fakeCoefs) => {
    const [planeCss, setPlaneCss] = useState({display:'block', bottom:'-1%', left:'4%'})
    const [ClassPlane, setClasses] = useState([classes.plane])
    const [displayGone, setGoneText] = useState('none')
    const ref = useRef(null)
    const planeRef = useRef(null)
    const [width, setWidth] = useState('')
    const [height, setHeight] = useState('')
    const [planePos, setPlanePos] = useState({left:'', top:''})
    const [ViewPos, setViewPos] = useState({left:'', top:''})
    const [planeSize, setPlaneSize] = useState({width:'', height:''})
    const [check, setTrue] = useState(false)

    
    const {isRound, setIsRound} = useContext(IsRoundContext)
    const {isPreparing, setIsPreparing} = useContext(IsPreparingContext)
    const {isPost, setIsPost} = useContext(isPostContext)
    
     
      useEffect(() => {
        const handleResize = () => {
          setWidth(ref.current.offsetWidth)
          setHeight(ref.current.offsetHeight)
          setViewPos({left : ref.current.getBoundingClientRect().left, top: ref.current.getBoundingClientRect().top})

        };
     
        window.addEventListener('resize', handleResize);
        
        return () => {
          window.removeEventListener('resize', handleResize);

        };
      }, []);

    useEffect(() =>{
        setWidth(ref.current.offsetWidth)
        setHeight(ref.current.offsetHeight)
        setViewPos({left : ref.current.getBoundingClientRect().left, top: ref.current.getBoundingClientRect().top})
    },[ref])

    useEffect(()=>{
        setPlanePos({left : planeRef.current.getBoundingClientRect().left, top: planeRef.current.getBoundingClientRect().top} )
        setPlaneSize({width: planeRef.current.getBoundingClientRect().width, height: planeRef.current.getBoundingClientRect().height})
    }, planeRef.current)

    useEffect(() =>{
        if (isPreparing){
            setPlaneCss({display:'block', bottom:'70%', left:'60%'})
        }
    },[isPreparing])

    useEffect(() => {
        if (isRound){
            
            setClasses([...ClassPlane, classes.plane_flying ])
            setPlaneCss({display:'block', bottom:'70%', left:'60%'})
        }else if(!isPreparing){
            setPlaneCss({display:'block', bottom:`${Number(planeRef.current.offsetTop) + Number(planeRef.current.offsetHeight)}px`, left:`${planeRef.current.offsetLeft}px`})
            setClasses([...ClassPlane , classes.plane_flyaway])
            setGoneText('block')
            
             setTimeout(function(){
                setPlaneCss({display:'none', bottom:'-1%', left:'4%'})
                setClasses([classes.plane])
             }, 1700)           
                        
        }
        
    }, [isRound])

    useEffect(() =>{
        if (isPost){
            setTimeout(function(){
                setIsPost(false)
                setIsPreparing(true)
            }, 3000)
        }else{
            setGoneText('none')
            setPlaneCss(preValue =>{return {display:preValue.display, bottom:'-1%', left:'4%'}})
        }
    },[isPost])


    function changePlane(){
        return [planeRef.current.getBoundingClientRect().left, planeRef.current.getBoundingClientRect().top]  
        
    }  
    


    return (
        <div className={classes.game} style={{ height: "99%" }}>
            <div className={classes.viewport} id="viewport" ref = {ref}>

                <img src="./media/background.svg" alt="Your Image" className={classes.rotating_image} />
                <Glow />

                <div className={ClassPlane.join(' ')} id="plane" ref={planeRef} style={{display: planeCss.display, bottom: planeCss.bottom, left: planeCss.left}}></div>

                <Svg  height={height} width={width} plane={planeSize} view={ViewPos} changePlane = {changePlane}/>
                <div className={classes.gone_away} style={{display: displayGone}} >UZAKLAŞ!</div>

                <Stopwatch fakeCoefs={fakeCoefs}/>

                <StartRound/>
                <AxisCoordinates />
                <AxisXCoordination />

            </div>
        </div>
    )
}

export default Game