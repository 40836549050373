import React  from 'react'
import classes from './FakeBidders.module.css'
import TableBidders from './TableBidders'

const FakeBidders = ({fakeCoefs, AmountBidders, fake_bidders_info}) => {
    
    return (
        <section className={classes.fake_bidders} style={{flexBasis: "25%"}}>
                <div className={classes.wrapper_one}>
                    <div className={`${classes.chose_bids} ${'fake_chose'}`}>
                        <a href="#" className={`${classes.all_chose_bid} ${classes.chosing_bid}`}><span>Tüm Bahisler</span></a>
                        <a href="#" className={`${'my_bids'} ${classes.chosing_bid}`} >Bahislerim</a>
                        <a href="#" className={`${'top'} ${classes.chosing_bid}`}>En iyi</a>
                    </div>
                    <div className={classes.info_bids}>
                        <div className={classes.all_bids}>
                            <h5>TÜM BAHİSLER</h5>
                            <h5 id="number_fake_bidders" >{AmountBidders}</h5>
                        </div>
                        <div className={classes.last_bidders}>
                            <img src="./media/history.svg" alt=""/>
                            <p>Önceki ei</p>
                        </div>
                    </div>
                    <div className={classes.divider}></div>
                    <div className={classes.bidders_table}>
                        <div className={classes.headers}>
                            <p className={classes.users}>Oyuncu</p>
                            <p className="try">Bahis, TRY x</p>
                            <p className="win_cell_header">Nakit çekme, TRY</p>
                        </div>
                        <TableBidders/>
                        
                    </div>
                </div>
                <div className={classes.production}>
                    <div className={classes.left}>
                        <p style={{color: "#6d6b78"}}>Bu ouyn</p>
                        <img src="./media/fair.svg" alt=""/>
                        <p style={{color: "aliceblue"}}>Probably Fair</p>
                    </div>
                    <div className={classes.right}>
                        <p style= {{color: "#6d6b78"}}>Powered by</p>
                        <img src="./media/spribe.svg" alt=""/>
                    </div>
                </div>
        </section>
  )
}

export default FakeBidders