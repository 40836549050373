import React, { useContext, useEffect, useState } from 'react'
import classes from './message-alert.module.css'
import { MessageAlertContext, IsRoundContext } from '../../context/allContext' 


const MsgAlert = (props) => {
    const {MessagesAlert, setMesAlert} = useContext(MessageAlertContext)
    const {isRound, setIsRound} = useContext(IsRoundContext)

    const [display, setDisplay] = useState('none')
    const [flyaway, setFlyaway] = useState(false)
    const [gone, setGone] = useState(false)
    const [transform, setTransform] = useState('none')

    useEffect(()=>{
      if(MessagesAlert.is && MessagesAlert[props.number]['coef']){
        setDisplay('flex')
        setFlyaway(true)
      }
    },[MessagesAlert.is, MessagesAlert[props.number]['coef']])

    useEffect(()=>{
      if(flyaway && !gone){
        setTimeout(function(){
          flyingAway()
          
        },3000)
      }
    },[flyaway])

    useEffect(()=>{
      if(isRound && gone){
        setGone(false)
      }
          
    },[isRound])

    function flyingAway(){
      setTransform('translateY(-360%)')
      setFlyaway(false)
      setTimeout(function(){
          setDisplay('none')
          setTransform('none')
          setGone(true)
          setMesAlert({is:false, 1:{coef:false, money:false}, 2:{coef:false, money:false}})
      }, 2000)
    }

    

  return (
    <div className={classes.message_alert} style={{display: display, transform: transform}}>
            <div className={classes.announcement}>
                <p style={{fontSize: "12px",  color: "#9b9aa1"}}>Parayı aldınız</p>
                <p ><span id="win_coef" >{MessagesAlert[props.number]['coef']}</span> x</p>
            </div>
            <div className={classes.win_info}>

                <img src="./media/star.png" alt="" className={classes.star_img}/>
                <p style={{fontSize: "12px", fontWeight: "900", textShadow: "1px 1px 2px rgba(11, 15, 1, 3)",
                color: "white"}} >Kazanç, TRY</p>
                <p id="win_amount"><span id="win_bid">{MessagesAlert[props.number]['money']}</span></p>
            </div>
            <div className={classes.close_button} onClick={flyingAway}></div>
    </div>
  )
}

export default MsgAlert